import React, {FormEvent, useMemo} from "react";
import classNames from "classnames";

import {FieldWrapper, getFieldState, IFieldState, InputCore, InputWrapper} from "@pg-design/inputs-module";
import {Select} from "@pg-design/select-module";

import {innerWrapper, inputStyle, selectStyle, wrapper} from "./PhoneField.module.css";

export type IPhoneFieldValue = {
    code: number;
    number: string;
};

interface ICountryCode {
    value: number;
    label: string;
}

interface IFieldProps<TName, TValue> {
    name: TName;
    value: TValue;
    error?: string[];
    onChange: (fieldName: string, value: TValue, shouldValidate?: boolean, file?: File | undefined) => void;
    onAfterChange: (fieldName: string, value: TValue) => void;
    checked: boolean;
}

export interface IPhoneFieldProps extends Omit<IFieldProps<string, IPhoneFieldValue>, "checked"> {
    codes: ICountryCode[];
    placeholder?: string;
    className?: string;
    name: string;
    error?: string[] | undefined;
    onChange: (field: string, value: IPhoneFieldValue) => void;
    onClick?: (e: React.MouseEvent) => void;
    onFocus?: (e: React.FocusEvent) => void;
    fieldState?: IFieldState;
    message?: string | undefined;
    maxMenuHeight?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PhoneField = <TValues extends Record<string, string>>(props: IPhoneFieldProps) => {
    const {codes, placeholder, className, error, message, maxMenuHeight, onClick, onFocus} = props;
    const options = useMemo(() => codes.map((code) => ({value: code.value, label: `${code.label} (+${code.value})`})), [props.codes]);
    const currentCode: ICountryCode | undefined = useMemo(() => options.find((option) => option.value === props.value.code), [props.value.code]);

    const onCodeChange = (code: ICountryCode) => {
        props.onChange(props.name, {...props.value, code: code.value});
    };
    const onNumberChange = (e: FormEvent<HTMLInputElement>) => {
        props.onChange(props.name, {code: props.value.code, number: e.currentTarget.value});
    };

    const fieldState = getFieldState(props);

    return (
        <FieldWrapper fieldState={fieldState} message={error?.join(", ") || message} className={classNames(wrapper, className)} onClick={onClick}>
            <InputWrapper fieldState={fieldState} noPadding>
                <div className={innerWrapper}>
                    <Select
                        instanceId={props.name + "_country_select"}
                        id={props.name + "_country_select"}
                        className={selectStyle}
                        isBorderless
                        options={options}
                        value={currentCode}
                        onChange={(value) => {
                            onCodeChange(value as ICountryCode);
                        }}
                        onFocus={onFocus}
                        menuPlacement="auto"
                        maxMenuHeight={maxMenuHeight}
                    />
                    <InputCore
                        onChange={onNumberChange}
                        className={inputStyle}
                        placeholder={placeholder}
                        value={props.value.number}
                        type="number"
                        pattern="[0-9]*"
                        onFocus={onFocus}
                    />
                </div>
            </InputWrapper>
        </FieldWrapper>
    );
};
import React, {FormEvent, useMemo} from "react";
import classNames from "classnames";

import {FieldWrapper, getFieldState, IFieldState, InputCore, InputWrapper} from "@pg-design/inputs-module";
import {Select} from "@pg-design/select-module";

import {innerWrapper, inputStyle, selectStyle, wrapper} from "./PhoneField.module.css";

export type IPhoneFieldValue = {
    code: number;
    number: string;
};

interface ICountryCode {
    value: number;
    label: string;
}

interface IFieldProps<TName, TValue> {
    name: TName;
    value: TValue;
    error?: string[];
    onChange: (fieldName: string, value: TValue, shouldValidate?: boolean, file?: File | undefined) => void;
    onAfterChange: (fieldName: string, value: TValue) => void;
    checked: boolean;
}

export interface IPhoneFieldProps extends Omit<IFieldProps<string, IPhoneFieldValue>, "checked"> {
    codes: ICountryCode[];
    placeholder?: string;
    className?: string;
    name: string;
    error?: string[] | undefined;
    onChange: (field: string, value: IPhoneFieldValue) => void;
    onClick?: (e: React.MouseEvent) => void;
    onFocus?: (e: React.FocusEvent) => void;
    fieldState?: IFieldState;
    message?: string | undefined;
    maxMenuHeight?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PhoneField = <TValues extends Record<string, string>>(props: IPhoneFieldProps) => {
    const {codes, placeholder, className, error, message, maxMenuHeight, onClick, onFocus} = props;
    const options = useMemo(() => codes.map((code) => ({value: code.value, label: `${code.label} (+${code.value})`})), [props.codes]);
    const currentCode: ICountryCode | undefined = useMemo(() => options.find((option) => option.value === props.value.code), [props.value.code]);

    const onCodeChange = (code: ICountryCode) => {
        props.onChange(props.name, {...props.value, code: code.value});
    };
    const onNumberChange = (e: FormEvent<HTMLInputElement>) => {
        props.onChange(props.name, {code: props.value.code, number: e.currentTarget.value});
    };

    const fieldState = getFieldState(props);

    return (
        <FieldWrapper fieldState={fieldState} message={error?.join(", ") || message} className={classNames(wrapper, className)} onClick={onClick}>
            <InputWrapper fieldState={fieldState} noPadding>
                <div className={innerWrapper}>
                    <Select
                        instanceId={props.name + "_country_select"}
                        id={props.name + "_country_select"}
                        className={selectStyle}
                        isBorderless
                        options={options}
                        value={currentCode}
                        onChange={(value) => {
                            onCodeChange(value as ICountryCode);
                        }}
                        onFocus={onFocus}
                        menuPlacement="auto"
                        maxMenuHeight={maxMenuHeight}
                    />
                    <InputCore
                        onChange={onNumberChange}
                        className={inputStyle}
                        placeholder={placeholder}
                        value={props.value.number}
                        type="number"
                        pattern="[0-9]*"
                        onFocus={onFocus}
                    />
                </div>
            </InputWrapper>
        </FieldWrapper>
    );
};
