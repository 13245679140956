import {apiV2Path} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IRankingPartner} from "../types/IRankingPartner";

export const getRankingPartners = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getRankingPartners: build.query<IPaginatedResponse<IRankingPartner>, void>({
            query: () => ({
                url: apiV2Path.ratings.partners,
                params: {
                    page_size: 1000
                }
            })
        })
    })
});

export const {useGetRankingPartnersQuery} = getRankingPartners;
import {apiV2Path} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IRankingPartner} from "../types/IRankingPartner";

export const getRankingPartners = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getRankingPartners: build.query<IPaginatedResponse<IRankingPartner>, void>({
            query: () => ({
                url: apiV2Path.ratings.partners,
                params: {
                    page_size: 1000
                }
            })
        })
    })
});

export const {useGetRankingPartnersQuery} = getRankingPartners;
