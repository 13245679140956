import {addNotification} from "../state/notifications_slice";

export const addWarningNotification = (message: string | JSX.Element, timeout?: number) =>
    addNotification({
        notification: {content: message, type: "warning"},
        timeout
    });
import {addNotification} from "../state/notifications_slice";

export const addWarningNotification = (message: string | JSX.Element, timeout?: number) =>
    addNotification({
        notification: {content: message, type: "warning"},
        timeout
    });
