import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IGetRegionListQuery} from "../types/IGetRegionListQuery";
import {IGetRegionListResponse} from "../types/IGetRegionListResponse";
import {IListRegion} from "../types/IListRegion";

const regionListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);

export const getRegionListApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getRegionList: build.query<IListRegion[], IGetRegionListQuery>({
            query: (params) => ({
                url: regionListApiLink,
                params: {
                    ...params
                }
            }),
            transformResponse(response: IGetRegionListResponse) {
                return response.results;
            }
        })
    })
});

export const {useGetRegionListQuery} = getRegionListApi;
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IGetRegionListQuery} from "../types/IGetRegionListQuery";
import {IGetRegionListResponse} from "../types/IGetRegionListResponse";
import {IListRegion} from "../types/IListRegion";

const regionListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);

export const getRegionListApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getRegionList: build.query<IListRegion[], IGetRegionListQuery>({
            query: (params) => ({
                url: regionListApiLink,
                params: {
                    ...params
                }
            }),
            transformResponse(response: IGetRegionListResponse) {
                return response.results;
            }
        })
    })
});

export const {useGetRegionListQuery} = getRegionListApi;
