import React from "react";
import {css, Theme} from "@emotion/react";

import {backgroundColor, p} from "@pg-design/helpers-css";

import {OfferLocationLinksList} from "../offer/atoms/OfferLocationLinksList";
import {IRecommendedRegion} from "../offer/atoms/OfferLocationLinksRecommendedRegions";
import {OfferLocationLinksWrapper} from "../offer/atoms/OfferLocationLinksWrapper";
import {RegionsVendorsLinks} from "../offer/atoms/RegionsVendorsLinks";
import {INearbyRegion} from "../offer/list/components/OfferListLocationLinks/OfferListLocationLinksNearbyRegions";
import {RegionType} from "../region/types/RegionType";

interface IProps {
    region?: {
        id: number;
        type: RegionType;
        parent?: {id: number} | null;
    };
    recommendedRegions: IRecommendedRegion[];
    nearbyRegions: INearbyRegion[];
}

const vendorPanelTitles = {
    RECOMMENDED_REGIONS_VENDORS: "Deweloperzy w pobliskich lokalizacjach",
    NEARBY_REGIONS_VENDORS: "Deweloperzy w pobliżu"
};

export const CITIES_EXCLUDED_FROM_LINKS_HIDING = [
    8647 // warsaw
];

export const VendorLinks: React.FC<IProps> = (props) => {
    const hasVendorsInRecommendedRegions = props.recommendedRegions.length > 0;
    const hasVendorsInNearbyRegions = props.nearbyRegions.length > 0;

    const isNotCounty = props.region?.type !== RegionType.COUNTY;
    const isNotDistrict = props.region?.type !== RegionType.DISTRICT;
    const isNotDistrictWithoutExcluded = isNotDistrict || CITIES_EXCLUDED_FROM_LINKS_HIDING.includes(props.region?.parent?.id || 0);
    const isNotNeighborhood = props.region?.type !== RegionType.NEIGHBORHOOD;

    const showRecommendedRegionsVendors = hasVendorsInRecommendedRegions && isNotCounty && isNotDistrictWithoutExcluded && isNotNeighborhood;
    const showNearbyRegionsVendors = hasVendorsInNearbyRegions && isNotDistrict && isNotNeighborhood;

    return showRecommendedRegionsVendors || showNearbyRegionsVendors ? (
        <OfferLocationLinksWrapper css={linksWrapperStyle}>
            {showRecommendedRegionsVendors && (
                <OfferLocationLinksList title={vendorPanelTitles.RECOMMENDED_REGIONS_VENDORS}>
                    <RegionsVendorsLinks regions={props.recommendedRegions} />
                </OfferLocationLinksList>
            )}
            {showNearbyRegionsVendors && (
                <OfferLocationLinksList title={vendorPanelTitles.NEARBY_REGIONS_VENDORS}>
                    <RegionsVendorsLinks regions={props.nearbyRegions} />
                </OfferLocationLinksList>
            )}
        </OfferLocationLinksWrapper>
    ) : null;
};

const linksWrapperStyle = (theme: Theme) => css`
    ${p(0)};
    ${backgroundColor("#fff")};

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(8, 0)};
    }
`;
import React from "react";
import {css, Theme} from "@emotion/react";

import {backgroundColor, p} from "@pg-design/helpers-css";

import {OfferLocationLinksList} from "../offer/atoms/OfferLocationLinksList";
import {IRecommendedRegion} from "../offer/atoms/OfferLocationLinksRecommendedRegions";
import {OfferLocationLinksWrapper} from "../offer/atoms/OfferLocationLinksWrapper";
import {RegionsVendorsLinks} from "../offer/atoms/RegionsVendorsLinks";
import {INearbyRegion} from "../offer/list/components/OfferListLocationLinks/OfferListLocationLinksNearbyRegions";
import {RegionType} from "../region/types/RegionType";

interface IProps {
    region?: {
        id: number;
        type: RegionType;
        parent?: {id: number} | null;
    };
    recommendedRegions: IRecommendedRegion[];
    nearbyRegions: INearbyRegion[];
}

const vendorPanelTitles = {
    RECOMMENDED_REGIONS_VENDORS: "Deweloperzy w pobliskich lokalizacjach",
    NEARBY_REGIONS_VENDORS: "Deweloperzy w pobliżu"
};

export const CITIES_EXCLUDED_FROM_LINKS_HIDING = [
    8647 // warsaw
];

export const VendorLinks: React.FC<IProps> = (props) => {
    const hasVendorsInRecommendedRegions = props.recommendedRegions.length > 0;
    const hasVendorsInNearbyRegions = props.nearbyRegions.length > 0;

    const isNotCounty = props.region?.type !== RegionType.COUNTY;
    const isNotDistrict = props.region?.type !== RegionType.DISTRICT;
    const isNotDistrictWithoutExcluded = isNotDistrict || CITIES_EXCLUDED_FROM_LINKS_HIDING.includes(props.region?.parent?.id || 0);
    const isNotNeighborhood = props.region?.type !== RegionType.NEIGHBORHOOD;

    const showRecommendedRegionsVendors = hasVendorsInRecommendedRegions && isNotCounty && isNotDistrictWithoutExcluded && isNotNeighborhood;
    const showNearbyRegionsVendors = hasVendorsInNearbyRegions && isNotDistrict && isNotNeighborhood;

    return showRecommendedRegionsVendors || showNearbyRegionsVendors ? (
        <OfferLocationLinksWrapper css={linksWrapperStyle}>
            {showRecommendedRegionsVendors && (
                <OfferLocationLinksList title={vendorPanelTitles.RECOMMENDED_REGIONS_VENDORS}>
                    <RegionsVendorsLinks regions={props.recommendedRegions} />
                </OfferLocationLinksList>
            )}
            {showNearbyRegionsVendors && (
                <OfferLocationLinksList title={vendorPanelTitles.NEARBY_REGIONS_VENDORS}>
                    <RegionsVendorsLinks regions={props.nearbyRegions} />
                </OfferLocationLinksList>
            )}
        </OfferLocationLinksWrapper>
    ) : null;
};

const linksWrapperStyle = (theme: Theme) => css`
    ${p(0)};
    ${backgroundColor("#fff")};

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(8, 0)};
    }
`;
