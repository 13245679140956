import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IOfferDetail} from "../types/IOfferDetail";
interface IGetOfferDetailListPayload {
    offerIds: number[];
}

export const getOfferDetailList = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getOfferDetailList: build.query<IOfferDetail[], IGetOfferDetailListPayload>({
            queryFn: async (queryParams, _api, _extraOptions, fetchWithBQ) => {
                const offerDetailRequests = queryParams.offerIds.map((offerId) => {
                    const offerDetailApiUrl = apiV2Link.offer.detail(Scenario.OFFER_DETAIL, {offerId});
                    return fetchWithBQ(offerDetailApiUrl);
                });

                const offerDetailsResponses = (await Promise.all(offerDetailRequests)) as {data: IOfferDetail; error?: FetchBaseQueryError}[];
                const offerDetailsErrors = offerDetailsResponses.filter((response) => response.error);

                if (offerDetailsErrors.length > 0 && offerDetailsErrors[0].error) {
                    return {
                        error: offerDetailsErrors[0].error
                    };
                }

                return {
                    data: offerDetailsResponses.map((response) => response.data)
                };
            }
        })
    })
});

export const {useGetOfferDetailListQuery} = getOfferDetailList;
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IOfferDetail} from "../types/IOfferDetail";
interface IGetOfferDetailListPayload {
    offerIds: number[];
}

export const getOfferDetailList = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getOfferDetailList: build.query<IOfferDetail[], IGetOfferDetailListPayload>({
            queryFn: async (queryParams, _api, _extraOptions, fetchWithBQ) => {
                const offerDetailRequests = queryParams.offerIds.map((offerId) => {
                    const offerDetailApiUrl = apiV2Link.offer.detail(Scenario.OFFER_DETAIL, {offerId});
                    return fetchWithBQ(offerDetailApiUrl);
                });

                const offerDetailsResponses = (await Promise.all(offerDetailRequests)) as {data: IOfferDetail; error?: FetchBaseQueryError}[];
                const offerDetailsErrors = offerDetailsResponses.filter((response) => response.error);

                if (offerDetailsErrors.length > 0 && offerDetailsErrors[0].error) {
                    return {
                        error: offerDetailsErrors[0].error
                    };
                }

                return {
                    data: offerDetailsResponses.map((response) => response.data)
                };
            }
        })
    })
});

export const {useGetOfferDetailListQuery} = getOfferDetailList;
