import React, {ForwardedRef, forwardRef, useRef, useState} from "react";
import classNames from "classnames";

import {useCombinedRefs} from "@pg-mono/hooks";

import {IInputWrapperProps, InputWrapper} from "../../atoms/wrapper/InputWrapper";
import {IFieldState} from "../../types";
import {IInputCoreProps, InputCore} from "./InputCore";

import {inputBase, inputBaseDisabled} from "./Input.module.css";

export type ITextInputProps = IInputWrapperProps & IInputCoreProps;
export type ITextInputOwnProps = ITextInputProps & {
    inputForwardedRef?: ForwardedRef<HTMLInputElement>;
    inputClassName?: string;
    inputStyle?: React.CSSProperties;
    inputWrapClassName?: string;
};

export const Input = forwardRef<HTMLDivElement, ITextInputOwnProps>((props, forwardedRef) => {
    // destructuring mostly to remove some props and spread rest into input core component.
    const {
        fieldState,
        detail,
        onFocus,
        onBlur,
        className,
        inputForwardedRef,
        rightElement,
        leftElement,
        elementCustomStyle,
        elementCustomClassName,
        inputClassName,
        inputWrapClassName,
        ...inputCoreProps
    } = props;
    const localRef = useRef<HTMLInputElement>(null);
    const inputRef = useCombinedRefs<HTMLInputElement>(inputForwardedRef, localRef);

    const [isFocused, setFocused] = useState(false);

    const events = {
        onFocus: () => {
            setFocused(true);
        },
        onBlur: () => {
            setFocused(false);
        },
        onClick: (event: React.MouseEvent) => {
            event.stopPropagation();

            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    };

    const innerFieldState: IFieldState = isFocused ? "focused" : fieldState ? fieldState : "default";

    const wrapperStyle = fieldState === "disabled" ? classNames(inputBaseDisabled, className) : classNames(inputBase, className);

    return (
        <div onClick={events.onClick} ref={forwardedRef} className={wrapperStyle}>
            <InputWrapper
                fieldState={innerFieldState}
                detail={detail}
                rightElement={rightElement}
                leftElement={leftElement}
                inputWrapClassName={inputWrapClassName}
                elementCustomClassName={elementCustomClassName}
                elementCustomStyle={elementCustomStyle}
            >
                <InputCore
                    {...inputCoreProps}
                    className={inputClassName}
                    ref={inputRef}
                    onFocus={(event) => {
                        events.onFocus();
                        onFocus && onFocus(event);
                    }}
                    onBlur={(event) => {
                        events.onBlur();
                        onBlur && onBlur(event);
                    }}
                />
            </InputWrapper>
        </div>
    );
});
import React, {ForwardedRef, forwardRef, useRef, useState} from "react";
import classNames from "classnames";

import {useCombinedRefs} from "@pg-mono/hooks";

import {IInputWrapperProps, InputWrapper} from "../../atoms/wrapper/InputWrapper";
import {IFieldState} from "../../types";
import {IInputCoreProps, InputCore} from "./InputCore";

import {inputBase, inputBaseDisabled} from "./Input.module.css";

export type ITextInputProps = IInputWrapperProps & IInputCoreProps;
export type ITextInputOwnProps = ITextInputProps & {
    inputForwardedRef?: ForwardedRef<HTMLInputElement>;
    inputClassName?: string;
    inputStyle?: React.CSSProperties;
    inputWrapClassName?: string;
};

export const Input = forwardRef<HTMLDivElement, ITextInputOwnProps>((props, forwardedRef) => {
    // destructuring mostly to remove some props and spread rest into input core component.
    const {
        fieldState,
        detail,
        onFocus,
        onBlur,
        className,
        inputForwardedRef,
        rightElement,
        leftElement,
        elementCustomStyle,
        elementCustomClassName,
        inputClassName,
        inputWrapClassName,
        ...inputCoreProps
    } = props;
    const localRef = useRef<HTMLInputElement>(null);
    const inputRef = useCombinedRefs<HTMLInputElement>(inputForwardedRef, localRef);

    const [isFocused, setFocused] = useState(false);

    const events = {
        onFocus: () => {
            setFocused(true);
        },
        onBlur: () => {
            setFocused(false);
        },
        onClick: (event: React.MouseEvent) => {
            event.stopPropagation();

            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    };

    const innerFieldState: IFieldState = isFocused ? "focused" : fieldState ? fieldState : "default";

    const wrapperStyle = fieldState === "disabled" ? classNames(inputBaseDisabled, className) : classNames(inputBase, className);

    return (
        <div onClick={events.onClick} ref={forwardedRef} className={wrapperStyle}>
            <InputWrapper
                fieldState={innerFieldState}
                detail={detail}
                rightElement={rightElement}
                leftElement={leftElement}
                inputWrapClassName={inputWrapClassName}
                elementCustomClassName={elementCustomClassName}
                elementCustomStyle={elementCustomStyle}
            >
                <InputCore
                    {...inputCoreProps}
                    className={inputClassName}
                    ref={inputRef}
                    onFocus={(event) => {
                        events.onFocus();
                        onFocus && onFocus(event);
                    }}
                    onBlur={(event) => {
                        events.onBlur();
                        onBlur && onBlur(event);
                    }}
                />
            </InputWrapper>
        </div>
    );
});
