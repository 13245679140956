import {LabelHTMLAttributes, ReactNode} from "react";

import {Text} from "@pg-design/text-module";

interface IProps extends LabelHTMLAttributes<HTMLLabelElement> {
    children: ReactNode;
}

export function FieldLabel(props: IProps) {
    const {children, ...labelProps} = props;

    return (
        <label {...labelProps}>
            <Text variant="body_copy_2" as="span">
                {children}
            </Text>
        </label>
    );
}
import {LabelHTMLAttributes, ReactNode} from "react";

import {Text} from "@pg-design/text-module";

interface IProps extends LabelHTMLAttributes<HTMLLabelElement> {
    children: ReactNode;
}

export function FieldLabel(props: IProps) {
    const {children, ...labelProps} = props;

    return (
        <label {...labelProps}>
            <Text variant="body_copy_2" as="span">
                {children}
            </Text>
        </label>
    );
}
